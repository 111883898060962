.dashboard {
  &__title {
    font-size: 32px;
    margin: 13px 0;
    color: $secondarycolor;
    font-weight: bold;
  }
  &__container {
    display: flex;
    background: #fff;
  }
  &__menu__container {
    width: 209px;
    height: 100vh;
    background: $primarycolor;
    &__links {
      padding: 0;

      font-family: $secondaryfont;
      cursor: pointer;
      &:hover {
        background: $secondarycolor;
      }

      p {
        margin-left: 12px;
        color: #fff;
        font-size: 15px;
      }
    }
  }
}

.read_one {
  margin-left: 15px;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
    color: $primarycolor;
    //font-size: 14px;
    font-weight: bold;
  }
}

.sidebar__icon {
  width: 30px;
}

//Dashboard menu body

.dashboard__content {
  &__container {
    width: 100%;
    margin: 25px 40px;
    font-family: $secondaryfont;
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__main {
    flex: 75%;
  }
  &__article {
    display: flex;
    justify-content: space-between;
    background: $secondarycolor;
    padding: 8px 12px;
    border-radius: 8px;
  }
  &__insight {
    padding: 5px 12px;
    &__title {
      font-size: 35px;
      font-weight: bold;
      margin-bottom: 80px;
      color: #fff;
    }
    &__create {
      background: $primarycolor;
      padding: 12px 10px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      border: none;
      font-size: 18px;
      border-radius: 5px;
      &:hover {
        background: #000;
      }
    }
  }
  &__img {
    img {
      width: 180px;
      object-fit: cover;
      height: 180px;
    }
  }

  &__recentarticles {
    border-radius: 8px;
    margin-top: 18px;
    background: #f1f2f3;
    h2 {
      font-size: 20px;
      font-weight: bold;
      padding: 15px 12px 0 15px;
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 25px;

      border-bottom: 1px solid #cfc9c9;
    }
    &__img {
      flex: 10%;
      margin-left: 15px;
      margin-bottom: 15px;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }
    &__text {
      flex: 60%;
      margin-bottom: 15px;
      margin-left: 20px;
      p {
        font-size: 14px;
      }
      span {
        font-size: 13px;
      }
    }
    &__infos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 60px 0 0;
      p {
        font-size: 13px;
      }
    }
  }
  &__aside {
    flex: 25%;
    margin: 0 15px;

    &__box {
      display: flex;
      align-items: center;
      background: $primarycolor;
      padding: 30px 12px;
      border-radius: 12px;
      margin: 0 0 25px 0;
      &__img {
        img {
          width: 30px;
          height: 30px;
        }
      }
      &__text {
        color: #fff;
        margin: 0 25px;
        p {
          font-weight: bold;
          font-size: 30px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}

.yellow-box {
  background: $secondarycolor;
  p,
  span {
    color: #fff;
  }
}

.art {
  margin-bottom: 15px;
  padding-right: 0;
  font-size: 14px;
  text-align: center;
  &::after {
    content: "" !important;
  }
}

.nav__links {
  display: flex;
  align-items: center;
  padding: 22px 12px;
}
.active-link {
  background: $secondarycolor;
}
