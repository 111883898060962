.pagination {
  &__container {
    position: relative;
  }
  &__all {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding-top: 20px;

    &__list {
      border-radius: 5px;
      border: 2px solid $primarycolor;
      padding: 8px 12px;
      margin-right: 15px;
      margin-bottom: 20px;
      cursor: pointer;
      &:hover {
        background: $secondarycolor;
        color: #fff;
        font-weight: bold;
        border: 2px solid $secondarycolor;
      }
    }
  }
}

.active {
  background: $secondarycolor;
  color: #fff;
  font-weight: bold;
  border: 2px solid $secondarycolor;
}
