.about {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $primarycolor;
    font-family: $secondaryfont;
    @include mobile-first {
      flex-direction: column-reverse;
      text-align: center;
      padding: 30px 0;
    }

    &__text {
      margin-left: 20px;
      &__title {
        font-size: 30px;
        color: #fff;
        margin-bottom: 50px;
        @include mobile-first {
          font-size: 25px;
          margin-top: 15px;
        }
      }
      &__btn {
        background: $secondarycolor;
        padding: 12px 18px;
        color: #fff !important;
        text-align: center;
        color: #000;
        font-weight: bold;
        font-size: 17px;
      }
    }
    &__img {
      margin-top: 50px;
      img {
        width: 80%;
        @include mobile-first {
          width: 60%;
        }
      }
    }
  }
  /*Partie Qui sommes-nous */
  &__teamwork {
    display: flex;
    justify-content: space-between;
    margin: 50px;
    @include mobile-first {
      flex-direction: column-reverse;
      text-align: center;
      margin: 25px 0;
    }

    &__img {
      width: 80%;
      @include mobile-first {
        width: 60%;
      }
    }
    &__title {
      @include mobile-first {
        margin: 25px 0;
      }
    }
    &__headlines {
      margin-top: 25px;
      color: $primarycolor;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 25px;
      @include mobile-first {
        font-size: 25px;
      }
    }
    &__text {
      font-family: $secondaryfont;
      font-size: 20px;
      @include mobile-first {
        font-size: 17px;
      }
    }
  }

  //Partie Comment travailler avec nous
  &__help {
    margin: 50px;
    &__text__container {
      text-align: center;
      &__title {
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 22px;
        color: $primarycolor;
        @include mobile-first {
          font-size: 22px;
        }
      }
      &__smalltitle {
        font-family: $secondaryfont;
        font-size: 20px;
        @include mobile-first {
          font-size: 17px;
        }
      }
    }
    &__img__container {
      margin: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mobile-first {
        flex-direction: column;
      }
      &__box {
        background: #f5f5f5;
        box-shadow: $boxShadow-2;
        padding: 8px 12px;
        font-size: 15px;
        width: 320px;
        text-align: center;
        font-family: $secondaryfont;
        @include mobile-first {
          margin: 20px 0;
        }
        &__img {
          width: 150px;
          height: 150px;
        }
        &__caption {
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          margin-bottom: 15px;
        }
        &__title {
          font-size: 15px;
          color: #fff;
        }
      }
    }
  }

  &__whyus {
    margin: 50px;
  }
  //Partie Fondateur & Citation
  &__founder {
    margin: 80px;
    @include mobile-first {
      margin: 40px 20px;
    }
    &__quote {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: $secondaryfont;

      @include mobile-first {
        flex-direction: column-reverse;
      }

      &__name {
        font-size: 32px;
        color: $primarycolor;

        margin-top: 25px;
        em {
          font-size: 20px;
          color: $secondarycolor;
          margin-bottom: 22px;
        }
      }
      &__title {
        font-size: 25px;
      }

      &__img {
        border-radius: 50%;
        padding: 18px;
        @include mobile-first {
          padding: 0;
        }
        img {
          width: 100%;
          //height: 250px;
        }
        &__text {
          font-size: 15px;
          color: $primarycolor;
          font-weight: bold;
        }
        em {
          font-size: 13px;
        }
      }
    }
  }
}
//Partie Pourquoi AlterEgo
.whyus__title {
  strong {
    color: $secondarycolor;
  }
}
.whyus__img__box {
  background: $secondarycolor;
  color: #000;
}

.whyus__caption {
  font-size: 25px;
  font-weight: bold;
}

.blue-caption {
  color: #fff;
}

.blue {
  background: $primarycolor;
  color: #fff;
  p {
    color: #fff;
  }
}

.text-large {
  color: $primarycolor;
}
.span {
  color: $primarycolor;
}
