@font-face {
  font-family: "primary";
  src: url("../assets/fonts/Trajan\ Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "secondary";
  src: url("../assets/fonts/RobotoSlab-VariableFont_wght.ttf");
  font-display: swap;
}

$primaryfont: "primary", serif;
$secondaryfont: "secondary", sans-serif;
