// .myContainer {
//   background-color: #ddd !important;
// }

.dashboard__content__create {
  &__article {
    &__container {
      background-color: #fff;
      border: 1px solid lightgrey;
      box-shadow: $boxShadow-2;
      padding: 25px 25px;
      max-height: 500px;
      overflow-y: auto;
      width: 65%;
    }
    &__headline {
      font-size: 40px;
      font-weight: bold;
    }
    &__title {
      display: flex;
      flex-direction: column;

      input {
        width: 100%;
        min-height: 50px;
        outline: none;
        border: 1px solid lightgray;
        &:focus {
          outline: none;
        }
      }
    }
    &__editor {
      margin: 25px 0;
    }
    &__file {
      margin: 0 auto;

      &__img {
        display: none;
        margin-top: 50px;
        img {
          width: 400px;
          height: 300px;
        }
      }
      &__label {
        display: block;
        background: $primarycolor;
        color: #fff;
        cursor: pointer;
        padding: 12px 6px;
        font-size: 18px;
        width: 200px;
        text-align: center;
        margin: 0 auto;
      }
      input[type='file'] {
        display: none;
      }
    }
    &__submit {
      display: flex;
      align-items: center;
      input[type='submit'] {
        background: $primarycolor;
        width: 15%;
        color: #fff;
        font-size: 20px;
        min-height: 42px;
        font-weight: bold;
        cursor: pointer;
        border: none;
        opacity: 0.92;
        margin-right: 50px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.label {
  margin: 15px 0;
  font-size: 18px;
  padding: 12px 0;
  font-weight: bold;
}

.cancel-article {
  background: #ddd;
  width: 15%;
  color: #000;
  font-size: 20px;
  min-height: 42px;
  font-weight: bold;
  text-align: center;
  line-height: 42px;
  opacity: 0.87;
  &:hover {
    opacity: 1;
  }
}

.isUpload {
  display: block;
}

.fileUpload {
  display: none;
}
