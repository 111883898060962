.spiner {
  position: fixed;
  bottom: 100px;
  left: 32%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  &__icon {
    width: 100px;
    height: 100px;
    border: 2px solid transparent;
    border-top-color: $primarycolor;
    border-radius: 50%;
    animation: circle 1s linear infinite;
  }
  &__logo {
    img {
      width: 250px;
      height: 250px;
    }
  }
}

.preloader-icon {
  width: 50px;
  height: 50px;
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
    border-top-color: $primarycolor;
  }
  100% {
    transform: rotate(360deg);
  }
}
