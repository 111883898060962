.container {
  background: #f1f2f1;
  font-family: $secondaryfont;
}

.blog {
  width: calc(100% - 2 * 32px);
  max-width: 725px;
  margin-left: auto;
  margin-right: auto;

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: calc(4 * 8px);
  }
  //Resumer de l'article du blog
  &__resume {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    @include mobile-first {
      flex-direction: column;
      align-items: flex-start;
    }
    &__img {
      margin-top: 27px;
      width: 100%;
      height: 100%;
      img {
        aspect-ratio: 200 / 200;
        max-width: 200px;
        max-height: 200px;
        object-fit: cover;
        @include mobile-first {
          width: 100%;
          max-width: 100%;
          height: 100%;
        }
      }
    }
    &__text {
      margin: 12px 25px;
      @include mobile-first {
        margin: 0;
      }

      &__insight {
        font-size: 30px;
        color: #000;
        font-weight: bolder;
        margin: 20px 0;
      }
      &__infos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
      }
      &__details {
        font-size: 15px;
        width: 80%;
      }
    }
  }

  .insight__link {
    &:hover {
      color: $primarycolor;
      text-decoration: underline;
    }
  }

  //Blog partie contenu à lire
  &__single {
    max-width: 700px;
    width: calc(100% - 32px * 2);
    margin-left: auto;
    margin-right: auto;
    font-family: $secondaryfont;
    &__headline {
      font-size: 65px;
      line-height: 1.2;
      color: $primarycolor;
      font-weight: bold;
      margin: 15px 0;
      @include tablette {
        font-size: 45px;
      }
      @include mobile-first {
        font-size: 30px;
      }
    }
    &__infos {
      opacity: 0.6;
      font-size: 17px;
      &__date {
        margin-right: 50px;
      }
    }
    &__img {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__text {
      font-size: 18px;
      margin-top: 1rem;
      margin-bottom: 30px;
      line-height: 1.8;
    }
  }
}

.page-s {
  padding-top: calc(5 * 8px);
  padding-bottom: calc(5 * 8px);
}

.empty-article {
  padding-top: 120px;
  text-align: center;
  height: 75vh;
  font-size: 50px;
  font-weight: bold;
}
