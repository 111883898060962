//Colors
$primarycolor: #07304c;
$secondarycolor:#005a76;
$blackcolor: #1e1e1e;
$opacitycolor: #cfcece;
$searchcolor: #f1f2f1;
$validecolor: #82cdb7;
$boxShadow-1: 1px 2px 0.5px 1px rgba(170, 170, 170, 0.733);
$boxShadow-2: 0 2px 4px #d8e1e8;
$deletebtn: rgba(255, 0, 0, 0.808);
$deg1: #005a76;
$btnhover: #333;
$bodercolor: #a0a0a0;
$bluecolor: #3cbae2;
//#fed81d