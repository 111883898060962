.update__article,
.delete__article {
  margin: 0 15px;
  background: $bodercolor;
  font-size: 15px;
  padding: 12px 15px;
  font-weight: bold;
  border-radius: 3px;
  color: #000;
  border: none;
  cursor: pointer;
  &:hover {
    background: $primarycolor;
    color: #fff;
  }
}

.delete__article {
  &:hover {
    background: $deletebtn;
  }
}

.update-textarea {
  resize: none;
  width: 100%;
  padding: 15px 12px;
  height: 325px;
  overflow: hidden;
  border: 1px solid lightgrey;
  border-radius: 3px;
  opacity: 0.89;
  margin-top: 20px;
}

.delete_user {
  &:hover {
    background: $deletebtn;
    color: #fff;
  }
}
