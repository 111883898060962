.dashboard__content__container {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__inbox {
    &__menu {
      display: flex;
      justify-content: space-between;
    }
  }
}
table {
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  width: 100%;
  table-layout: fixed;
}
thead {
  height: 50px;
}

th,
td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}
th {
  background-color: #f1f3f4;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}
td {
  background-color: #fff;
  font-size: 14px;
  color: #333;
  text-align: center;
  padding-right: 20px !important;
}
tr:hover {
  background-color: #f5f5f5;
}

.content-blog {
  font-size: 15px;
  width: 80%;
}
.content {
  overflow: hidden;
  padding-right: 16px;
  line-height: 24px;
  max-height: calc(24px * 3);
  font-size: 16px;
  position: relative;
  text-align: justify;
  &::after {
    content: '...';
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

//Read one messages

.dashboard__content__readinfos {
  &__container {
    border: 1px solid lightgray;
    box-shadow: $boxShadow-2;
    border-radius: 6px;
    padding: 20px 10px;
    background: #fff;
  }
  &__userinfos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    border: 1px solid lightgray;

    &__contact {
      display: flex;
      align-items: center;

      p {
        font-size: 16px;
        font-weight: bold;
      }
      span {
        font-size: 12px;
        opacity: 0.8;
        margin-left: 12px;
      }
    }
    &__date {
      font-size: 12px;
      opacity: 0.8;
    }
  }
  &__message {
    p {
      font-size: 15.5px;
      font-weight: 350;
      padding: 25px 0;
    }
  }
}

.msg__btns {
  margin-top: 25px;
}

.message_link {
  &:hover {
    text-decoration: underline;
    font-weight: bold;
    color: $primarycolor;
  }
}

.cancel {
  background: #ddd;
  opacity: 0.89;
  &:hover {
    background: #ddd;
    opacity: 1;
    color: #000;
  }
}
