//Home page contact-Us
.contact-us {
  margin: 50px;
  @include mobile-first {
    margin: 50px 0;
  }

  &__title {
    font-size: 45px;
    font-weight: bold;
    color: $primarycolor;
    margin-bottom: 50px;
    text-align: center;
    @include mobile-first {
      font-size: 25px;
    }
  }
  &__form {
    margin-top: 40px;
    font-family: $secondaryfont;
    background: $primarycolor;
    padding: 40px 15px;
    width: 50%;
    margin: 0 auto;
    @include mobile-first {
      width: 100%;
    }
    label {
      color: #fff;
      margin-bottom: 20px;
    }
    &__input__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
      @include mobile-first {
        flex-direction: column;
      }
    }
    &__input {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include mobile-first {
        margin-left: 0;
        padding: 0 15px;
      }

      input {
        height: 40px;
        @include mobile-first {
          margin: 10px 0;
        }
      }
    }
    &__textarea {
      display: flex;
      flex-direction: column;
      padding: 40px 15px;
      width: 100%;
      textarea {
        resize: none;
        height: 200px;
      }
    }
  }

  &__submit {
    background: $secondarycolor;
    width: 25%;
    height: 45px;
    border: 1px solid $secondarycolor;
    border-radius: 8px;
    margin-left: 15px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    opacity: 0.92;
    &:hover {
      opacity: 1;
    }
    @include mobile-first {
      margin-left: 0;
      width: 100%;
    }
  }
}

//Contact-Us Page
.contact-page {
  margin: 100px;
  @include mobile-first {
    margin: 10px 25px;
  }

  &__input__container {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
  &__container {
    margin-top: 25px;
    &__hgroup {
      text-align: center;
      &__title {
        font-size: 40px;
        font-weight: bold;
        color: $primarycolor;
        @include mobile-first {
          font-size: 30px;
          margin: 25px 0;
        }
      }
      &__smalltitle {
        font-size: 25px;
        color: #000;
        font-family: $secondaryfont;
      }
    }
  }
  &__newForm {
    width: 100%;
    @include mobile-first {
      flex: 1;
    }
  }
  &__infos {
    &__form__container {
      display: flex;
      margin: 0 auto;
      @include mobile-first {
        flex-direction: column;
      }
    }

    &__address {
      position: relative;
      right: 50px;
      top: 50px;
      background-color: $secondarycolor;
      height: calc(100vh + 55px);
      padding: 40px 15px;
      flex: 40%;
      @include mobile-first {
        flex: 1;
        width: 100%;
        right: 0;
       top: -50px;
      }

      font-family: $secondaryfont;
      color: #fff;
      &__title {
        @include mobile-first {
          font-size: 30px;
        }
        font-size: 45px;
        font-weight: bold;
      }
      &__infos {
        display: flex;
        flex-direction: column;
        margin: 50px 0;
        font-size: 18px;
      }
    }
  }

  &__formgroup {
    flex: 60%;
    @include mobile-first {
      flex: 100%;
      width: 100%;
    }
  }

  .icons {
    width: 35px;
    height: 35px;
    color: #fff;
  }
  .fb {
    &:hover {
      color: #1877f2;
    }
  }

  .lkdin {
    &:hover {
      color: #0077b5;
    }
  }
  .insta {
    &:hover {
      color: #c32aa3;
    }
  }
}
