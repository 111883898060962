.footer {
   padding: 15px 8px;
   background: $primarycolor;
   font-family: $secondaryfont;
   &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mobile-first {
         flex-direction: column;
      }
   }
   &__logo__img {
         width: 130px;
         height: 130px;

   }
   .copyright {
      font-size: 15px;
      color: #fff;
      font-weight: bold;
   }
   &__socialmedia {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__link {
         margin: 12px;
      }
   }
   .icons {
      margin-left: 20px;
      width: 24px;
      height: 24px;
      color: white;
   }
   .fb {
      &:hover {
         color: #1877f2;
      }
   }

   .lkdin {
      &:hover {
         color: #0077b5;
      }
   }
   .insta {
      &:hover {
         color: #c32aa3;
      }
   }
}
